import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeRedo,
  placeStartOver,
  placeBoldText,
  placeLeftText
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph =createSpace(-8,8,-7,9);
makeResponsive(graph);
graph.suspendUpdate();
graph.options.layer['image']=26;
graph.options.layer['text']=2;
graph.options.layer['html']=2;
graph.options.layer['line']=2;
graph.options.layer['point']=10;
graph.options.layer['circle']=1;
graph.options.image.highlight=false;
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2-2;
const height = (boundingBox[1]-boundingBox[3])/2;
/**********************PUT AXES **********************/
//var ax = createAxes(graph);
/***************** PUT AXES TITLE *******************/
//ax[0].setAttribute({name:'X'});
//ax[1].setAttribute({name:'Y'});
/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var scoreCard = writeHTMLText(graph, positionX, positionY+3, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY+2, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
/******************ITERATIVE VARIABLE **************/
var i=0; var k=0; var vis=false;
/*************************ANSWERS*********************/
var index_selected_answer = -1;
const answers = [['1/6', '5/6', '1/2', '1/3'], ['2/3', '1/3', '5/6', '3/6'],['2/4', '3/4', '4/5', '4/4'],
[ '1/3', '2/3', '5/6', '3/6'], ['2/5', '3/5', '5/5', '4/5']];
const index_right_answer = [1,0,3,1,2];
/**/
const PtmList = [1/8, 1/6, 1/5, 1/4, 1/3];
var Ptm = drawPoint(graph, PtmList[0], 8);
Ptm.setAttribute({visible:false});
/**/
/********************************************************************************************/
/********************************************************************************************/
var lab1 = placeLeftText(graph, positionX-3, positionY+6.5, 'All possible outcomes in roll of a dice');
lab1.setAttribute({anchorX:'left'});
var dice=[];
dice[0] = placeImage(graph, '/assets/dice-1-red.svg', positionX-3, 4.5+positionY, 3);//
dice[0].setAttribute({rotate:20});
dice[1] =placeImage(graph, '/assets/dice-2-red.svg', positionX-0.5, 4.5+positionY, 3);//
dice[1].setAttribute({rotate:70});
dice[2] =placeImage(graph, '/assets/dice-3-red.svg', positionX+1.5, 4.5+positionY+0.1, 3);//
dice[2].setAttribute({rotate:105});
dice[3] =placeImage(graph, '/assets/dice-4-red.svg', positionX+2.5, 4.5+positionY, 3);//
dice[3].setAttribute({rotate:45});
dice[4] =placeImage(graph, '/assets/dice-5-red.svg', positionX+4.5, 4.5+positionY, 3);//
dice[4].setAttribute({rotate:70});
dice[5] =placeImage(graph, '/assets/dice-6-red.svg', positionX+6.5, 4.5+positionY+0.5, 3);//
dice[5].setAttribute({rotate:120});
/*******************************************************/
var EventA=[];
EventA[0] = placeImage(graph, '/assets/dice-1-red.svg', positionX-3, 1.5+positionY, 3);//
EventA[0].setAttribute({visible:()=>i==1||i==2 || i==4 ,rotate:20});
EventA[1] =placeImage(graph, '/assets/dice-2-red.svg', positionX-0.5, 1.5+positionY, 3);//
EventA[1].setAttribute({visible:()=>i==0||i==2||i==4, rotate:70});
EventA[2] =placeImage(graph, '/assets/dice-3-red.svg', positionX+1.5, 1.5+positionY+0.1, 3);//
EventA[2].setAttribute({visible:()=>i==1||i==3||i==2||i==4, rotate:105});
EventA[3] =placeImage(graph, '/assets/dice-4-red.svg', positionX+2.5, 1.5+positionY, 3);//
EventA[3].setAttribute({visible:()=>i==0||i==3||i==2||i==4,rotate:45});
EventA[4] =placeImage(graph, '/assets/dice-5-red.svg', positionX+4.5, 1.5+positionY, 3);//
EventA[4].setAttribute({visible:()=>i==1||i==3||i==2, rotate:70});
EventA[5] =placeImage(graph, '/assets/dice-6-red.svg', positionX+6.5, 1.5+positionY+0.5, 3);//
EventA[5].setAttribute({visible:()=>i==0||i==3,rotate:120});
/******************************************************/
var EventAName = [];
EventAName[0] = placeLeftText(graph, positionX-3, positionY+3.5, 'Event A : Outome is an even number');
EventAName[0].setAttribute({visible:()=>i==0, anchorX:'left'});
EventAName[1] = placeLeftText(graph, positionX-3, positionY+3.5, 'Event A: Outome is an odd number');
EventAName[1].setAttribute({visible:()=>i==1, anchorX:'left'});
EventAName[2] = placeLeftText(graph, positionX-3, positionY+3.5, 'Event A: Outome is a number < 6');
EventAName[2].setAttribute({visible:()=>i==2, anchorX:'left'});
EventAName[3] = placeLeftText(graph, positionX-3, 3.5+positionY, 'Event A: Outome is a number > 2');
EventAName[3].setAttribute({visible:()=>i==3, anchorX:'left'});
EventAName[4] = placeLeftText(graph, positionX-3, 3.5+positionY, 'Event A: Outome is a number < 5');
EventAName[4].setAttribute({visible:()=>i==4, anchorX:'left'});
/***********************************************************************************/
var EventBName = [];
EventBName[0] = placeLeftText(graph, positionX-3, positionY+0.5, 'Event B : Outome is >2');
EventBName[0].setAttribute({visible:()=>i==0, anchorX:'left'});
EventBName[1] = placeLeftText(graph, positionX-3, positionY+0.5, 'Event B: Outome is <4');
EventBName[1].setAttribute({visible:()=>i==1, anchorX:'left'});
EventBName[2] = placeLeftText(graph, positionX-3, positionY+0.5, 'Event B: Outome is a nubmer divisible by 3');
EventBName[2].setAttribute({visible:()=>i==2, anchorX:'left'});
EventBName[3] = placeLeftText(graph, positionX-3, positionY+0.5, 'Event B: Outome is a number divisible by 4');
EventBName[3].setAttribute({visible:()=>i==3, anchorX:'left'});
EventBName[4] = placeLeftText(graph, positionX-3, positionY+0.5, 'Event B: Outome is a number > 1');
EventBName[4].setAttribute({visible:()=>i==4, anchorX:'left'});
/******************************************************************************************/
var EventB=[];
EventB[0] = placeImage(graph, '/assets/dice-1-red.svg', positionX-3,  positionY-1.5, 3);//
EventB[0].setAttribute({visible:()=>i==1 ,rotate:20});
EventB[1] =placeImage(graph, '/assets/dice-2-red.svg', positionX-0.5, positionY-1.5, 3);//
EventB[1].setAttribute({visible:()=>i==1||i==4, rotate:70});
EventB[2] =placeImage(graph, '/assets/dice-3-red.svg', positionX+1.5, positionY+0.1-1.5, 3);//
EventB[2].setAttribute({visible:()=>i==0||i==1||i==2||i==4, rotate:105});
EventB[3] =placeImage(graph, '/assets/dice-4-red.svg', positionX+2.5, positionY-1.5, 3);//
EventB[3].setAttribute({visible:()=>i==0||i==3||i==4,rotate:45});
EventB[4] =placeImage(graph, '/assets/dice-5-red.svg', positionX+4.5, positionY-1.5, 3);//
EventB[4].setAttribute({visible:()=>i==0||i==4, rotate:70});
EventB[5] =placeImage(graph, '/assets/dice-6-red.svg', positionX+6.5, positionY+0.5-1.5, 3);//
EventB[5].setAttribute({visible:()=>i==0||i==2||i==4,rotate:120});
/*************************************PRINT ANSWER ****************************************/
//print(graph, ()=> (0.5*(180-2*alpha.Value()*180/Math.PI)).toFixed(1))
/****************PUT TITLE ****************************/
var question = placeQuestion(graph, function(){return 'Conditional Probability P(B|A) is &mdash;'});
var comment = placeComment(graph, '');
var note = writeHTMLText(graph, positionX, positionY+height/2., 'You did not make a selection.');
note.setAttribute({visible:false});
//print(graph, ()=>(alpha.Value()*180/Math.PI).toFixed(1));
///////////////////////////GRAPHICS MODULES//////////////////
var bck =placeWhite(graph);
var show =placeBCircles(graph);
var hide=placeCircles(graph);
var check = placeChecks(graph);
var cross = placeCross(graph);
var exclaim = placeExclaim(graph);
var pointers = placeFingers(graph);
hide[0].setAttribute({visible:false});
/***************************BUTTON MODULES******************/
var test =placeTest(graph,'left');
hoverMe(graph, test, 'Check Your Answer', -20, 0);
/**/
var next = placeRedo(graph);
hoverMe(graph, next, 'Next Question', 0, 0);
/**/
var redo = placeStartOver(graph);
redo.setAttribute({visible:false});
hoverMe(graph, redo, 'Start Over', 0, 0);
/***************************************/
///////////////////////////////////////////////////////////////
var ansList = ['1/6', '5/6', '1/2', '1/3'];
var ansArray = placeAnswers(graph, ansList);
for(let ij=0; ij<=3; ij++)
{
  ansArray[ij].on('down', function(){
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray[ij].setAttribute({color:'black'});
  pointers[ij].setAttribute({visible:true});
  index_selected_answer = ij.valueOf();})
}
////////////////////////////////////////////////////////////////////////
var hint =writeHTMLText(graph, positionX, positionY+2, '<ol><li> Find the probabilities of event A and B occurring separately. </li><br> <li> Find the probability of events A and B occurring simultaneously. </li><br> <li> Then, use the formula P(AUB) = P(A)+P(B)-P(A &cap; B).</li> </ol>');
hint.setAttribute({visible:false, color:'white'});
/**************************TEST BUTTON ***********************/
test.on('down', function()
{
  note.setAttribute({visible:false});
  if(i<=PtmList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<PtmList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<PtmList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
      }
	  else if (index_selected_answer == -1 && yourTotal<PtmList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
        note.setAttribute({visible:true});
	  }
  }
});
/*****************************SHUFFLE BUTTON ***********************/
next.on('down', function(){
  note.setAttribute({visible:false});
  if(i<=PtmList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<PtmList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<PtmList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
		    yourWrong = yourWrong+1;
      }
	  else if (index_selected_answer == -1 && yourTotal<PtmList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
		    yourMissed = yourMissed+1;
	  }
	  yourTotal = yourScore + yourWrong + yourMissed;
  }
  if(i==PtmList.length-1)
  {
  bck.setAttribute({opacity:1});
  scoreCard.setAttribute({visible:true});
  missedCard.setAttribute({visible:true});
  wrongCard.setAttribute({visible:true});
  redo.setAttribute({visible:true});
  ansList[0]=answers[0][0];
  ansList[1]=answers[0][1];
  ansList[2]=answers[0][2];
  ansList[3]=answers[0][3];
  ansArray.forEach(function(item){item.setAttribute({visible:false})});
  question.setAttribute({visible:false});

  }
  else{
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    i=i+1;
    ansList[0]=answers[i][0];
    ansList[1]=answers[i][1];
    ansList[2]=answers[i][2];
    ansList[3]=answers[i][3];
  }
  hide[i].setAttribute({visible:false});
  hint.setAttribute({visible:false});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  index_selected_answer = -1;
  /////////////////////////ALL CHANGES GO HERE////////////////////////
  Ptm.moveTo([PtmList[i], 8], 200);
  ///////////////////////////////////////////////////////////////////
});
/************************************************/
redo.on('down', function(){
	//Hiding all blue circles
	  hide.forEach(function(item){item.setAttribute({visible:true})});
    hide[0].setAttribute({visible:false});
  //Hiding all check marks
    check.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all cross marks //
    cross.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all exclaimation marks
	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
  ///////////////resetting scores//////////////////
    note.setAttribute({visible:false});
	  yourScore=0;
	  yourMissed=0;
	  yourWrong=0;
	  yourTotal=0;
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
  /////////////////////////////////////////////
	  i=0;
	  ansList[0]=answers[0][0];
	  ansList[1]=answers[0][1];
	  ansList[2]=answers[0][2];
	  ansList[3]=answers[0][3];
	  bck.setAttribute({opacity:0});
	  pointers.forEach(function(item){item.setAttribute({visible:false})});
    ansArray.forEach(function(item){item.setAttribute({visible:true, color:'grey'})});
	  question.setAttribute({visible:true});
    comment.setAttribute({visible:true});
	  hint.setAttribute({visible:false});
	  index_selected_answer = -1;
    redo.setAttribute({visible:false});
    ////////////////////////ALL CHANGES GO HERE/////////////
    Ptm.moveTo([PtmList[0], 8], 200);
    ////////////////////////////////////////////////////////
});
/********************PLACE LOGO ********************/
var edliy = placeLogo(graph);
hoverMe(graph, edliy, 'Tap to see a hint', 0, 0);
edliy.on('down',
function(){
  k = toggle(k);
	vis = toggleTF(vis);
  bck.setAttribute({opacity:k});
	scoreCard.setAttribute({visible:false});
	missedCard.setAttribute({visible:false});
	wrongCard.setAttribute({visible:false});
  hint.setAttribute({visible:vis});
	question.setAttribute({visible:!vis});
	ansArray.forEach(function(item){item.setAttribute({visible:!vis})});
  note.setAttribute({visible:false});
  ////////////////////////Code specific changes go here/////////////////
  //////////////////////////////////////////////////////////////////////
});
graph.unsuspendUpdate();
/******************END OF FILE ***************************/
}
}
export default Boxes;
